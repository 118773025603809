<template>
  <div class="process-wrapper">
    <div v-if="!claimed" class="ml-6 mt-4">
      <TaskBlocker :assignee="data.assignee" />
    </div>
    <div class="prozess">
      <div v-if="!isLoading && numberBlockList">
        <h3>
          Aktueller Rufnummernbestand Vorwahlbereich {{ nationalDestinationCode }}
        </h3>

        <v-data-table
          :headers="numberBlockListHeader"
          :items="numberBlockList"
          :items-per-page="5"
          class="elevation-1 mb-4"
        >
          <template v-slot:[`item.percentage`]="{ item }">
            {{ toPercent(item.amountFree / item.totalSize) }}%
          </template>
        </v-data-table>

        <DisplayContainer label="Neuer bei oCS hinterlegter Block">
          <div class="input-row">
            <DataDisplay label="Ländervorwahl">
              {{ newBlock.countryCode }}
            </DataDisplay>
            <DataDisplay label="Vorwahl">
              {{ newBlock.areaCode }}
            </DataDisplay>
            <DataDisplay label="Nummer">
              {{ newBlock.number }}
            </DataDisplay>
            <DataDisplay label="DDI">
              {{ newBlock.numberDdi }}
            </DataDisplay>
            <DataDisplay label="Blockgröße">
              {{ newBlock.size }}
            </DataDisplay>
            <DataDisplay label="Schaltdatum">
              {{ newBlock.switchDate }}
            </DataDisplay>
          </div>
        </DisplayContainer>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper mt-4">
        <v-btn
          color="primary"
          large
          :disabled="!claimed"
          :loading="isSubmitting"
          @click="completeTask()"
        >
          Rufnummernblock hinterlegt
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionRufnummernblockTsCfgEinpflegenHumanTask',
  components: {
    TaskBlocker: () => import('../TaskBlocker.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      isSubmitting: false,
      datePickerMenu: false,
      nationalDestinationCode: 0,
      numberBlockList: null,
      totalNumbers: 0,
      totalFreeNumbers: 0,
      totalPercentage: 0.0,
      newBlock: {
        countryCode: '49',
        areaCode: '',
        number: '',
        numberDdi: '',
        size: 1000,
        switchDate: new Date().toISOString().substring(0, 10)
      },
      numberBlockListHeader: [
        {
          text: 'Vorwahl',
          align: 'start',
          sortable: false,
          value: 'nationalDestinationCode',
        },
        {
          text: 'Rufnummer',
          align: 'start',
          sortable: false,
          value: 'subscriberNumber',
        },
        {
          text: 'Blockgröße',
          align: 'start',
          sortable: false,
          value: 'totalSize',
        },
        {
          text: 'Nummern in Quarantäne',
          align: 'start',
          sortable: false,
          value: 'amountQuarantined',
        },
        {
          text: 'Anzahl freier Nummern',
          align: 'start',
          sortable: false,
          value: 'amountFree',
        },
        {
          text: '% frei',
          align: 'start',
          sortable: false,
          value: 'percentage',
        },
      ],
      numberRules: [
        v => !!v || 'Notwendiges Feld',
        v => /^\d+$/.test(v) || '',
        v => v > 0 || 'Muss größer 0 sein',
      ],
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
  },
  mounted: function () {
    this.loadTaskData();
  },
  methods: {
    loadTaskData: async function () {
      this.isLoading = true;
      await this.data.variables.then((data) => {
        const dtoVariableName = `numberBlockRequestDto_${data.loopCounter.value}`;
        this.newBlock = { ...data[dtoVariableName].value };
        this.nationalDestinationCode = data.nationalDestinationCode.value;
        this.newBlock.areaCode = this.nationalDestinationCode;
        this.isLoading = false;
      });
      this.fetchNumberList();
    },
    fetchNumberList: async function () {
      HTTP.get(`/numberBlock/byNdc/${this.nationalDestinationCode}`)
        .then(({ data }) => {
          this.totalNumbers = data.reduce((partialTotal, row) => partialTotal + row.totalSize, 0);
          this.totalFreeNumbers = data.reduce((partialTotal, row) => partialTotal + row.amountFree, 0);
          this.totalPercentage = this.toPercent(this.totalFreeNumbers / this.totalNumbers);
          this.numberBlockList = data;
        })
        .catch((err) => {
          this.showToast('Fehler beim laden der Prozessdaten');
          console.error(err);
        });
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {})
        .then(() => {
          this.showToast('Die Aufgabe wurde erfolgreich abgeschlossen', 'info');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() => this.showToast('Ein Fehler ist aufgetreten'));
    },
    toPercent(number) {
      return (Math.round(number * 10000) / 100);
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
}
</script>
<style scoped>
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}
</style>
